/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {navigate} from 'gatsby';
import {Box, Card, CardContent, CardMedia, Grid, Paper, Typography} from '@mui/material';
import {SearchInput, SearchProvider, SearchResults} from '../supersupplier';
import buyerSearch from '../supersupplier/assets/buyer-search.jpg';
import {DefaultLayout as MDXLayout} from "../components/DefaultLayout";
export {Head} from '../components/DefaultLayout';
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    h5: "h5",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Block, BackgroundImage, Spacer, Link, FeatureGrid, TextPanel, TextPanelSide, TextPanelContent, CTAContactForm} = _components;
  if (!BackgroundImage) _missingMdxReference("BackgroundImage", true);
  if (!Block) _missingMdxReference("Block", true);
  if (!CTAContactForm) _missingMdxReference("CTAContactForm", true);
  if (!FeatureGrid) _missingMdxReference("FeatureGrid", true);
  if (!Link) _missingMdxReference("Link", true);
  if (!Spacer) _missingMdxReference("Spacer", true);
  if (!TextPanel) _missingMdxReference("TextPanel", true);
  if (!TextPanelContent) _missingMdxReference("TextPanelContent", true);
  if (!TextPanelSide) _missingMdxReference("TextPanelSide", true);
  return React.createElement(React.Fragment, null, React.createElement(Block, {
    color: 'neutral',
    style: {
      minHeight: 800,
      position: 'relative'
    }
  }, React.createElement(BackgroundImage, {
    src: 'https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
  }), React.createElement(Spacer, {
    size: 30
  }), React.createElement("center", null, React.createElement(_components.h3, null, "Find B2B suppliers. Fast"), React.createElement(Spacer, {
    size: 4
  })), React.createElement(Box, {
    sx: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 1
    }
  }, React.createElement(SearchInput), React.createElement(Box, {
    sx: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      py: 2
    }
  }, React.createElement(Typography, {
    sx: {
      mr: 1
    }
  }, "Now available in the UK!"), "\n", React.createElement(Link, {
    to: 'https://m2north.com/signup'
  }, "Become a SuperSupplier today"))), React.createElement(Spacer, {
    size: 30
  })), "\n", React.createElement(Block, {
    color: 'neutral'
  }, React.createElement(FeatureGrid, {
    columns: 3,
    features: [{
      icon: 'place',
      title: 'Find local suppliers',
      description: "Because our results are location-aware, it's easier to support suppliers in your area."
    }, {
      icon: 'military_tech',
      title: 'View accreditation',
      description: 'Suppliers are pre-vetted, badged and ranked.'
    }, {
      icon: 'bolt',
      title: 'Get quotes. Fast.',
      description: 'Send enquiries and track responses, directly.'
    }]
  })), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(TextPanel, null, React.createElement(TextPanelSide, null, React.createElement(Box, {
    component: 'img',
    src: buyerSearch,
    sx: {
      borderRadius: 1.5,
      height: {
        sm: '100%',
        md: '50%'
      },
      maxWidth: '100%',
      objectFit: 'contain'
    }
  })), React.createElement(TextPanelContent, null, React.createElement(_components.h3, null, "Product Sourcing"), React.createElement(_components.h5, null, "It isn't easy to find products or suppliers using a search engine."), React.createElement(_components.p, null, "Here's a typical scenario. You urgently need to find a seller and place an order. You open up your web browser and try a search, but end up with pages of irrelevant results."), React.createElement(_components.p, null, "Because search engines can't answer these questions:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Can I trust this seller?"), "\n", React.createElement(_components.li, null, "Can I trust this product?"), "\n", React.createElement(_components.li, null, "Is this price reasonable?"), "\n", React.createElement(_components.li, null, "Who else buys this product?"), "\n", React.createElement(_components.li, null, "Is the seller accredited?"), "\n", React.createElement(_components.li, null, "Is there a seller nearby?"), "\n"), React.createElement(_components.p, null, "This is what SuperSupplier is all about."), React.createElement(_components.p, null, "We are an organised, trusted business community of sellers, all ready to do business."), React.createElement(_components.p, null, "Now you can easily search for trusted products and accredited sellers, make enquiries and place orders."), React.createElement(_components.p, null, "Next time you're looking for a product, use SuperSupplier by M2North. Source, don't search.")))), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(FeatureGrid, {
    columns: 4,
    features: [{
      icon: 'add_shopping_cart',
      title: 'Sourcing',
      description: 'Find quality sellers using our Sourcing.'
    }, {
      icon: 'verified',
      title: 'Badging',
      description: 'Compare sellers using our Badging (including BBBEE status).'
    }, {
      icon: 'request_quote',
      title: 'Quoting',
      description: 'Get multiple quotes using our Quoting.'
    }, {
      icon: 'auto_awesome',
      title: 'Order Distribution',
      description: 'Send orders directly from your ERP system using our Order Distribution.'
    }]
  })), "\n", React.createElement(Block, {
    color: 'charcoal'
  }, React.createElement("center", null, React.createElement(_components.h5, null, "Are you ready to take your business to the next level?"), React.createElement(Spacer, {
    size: 2
  }), React.createElement(CTAContactForm, {
    buttonSize: 'x-large',
    defaultMessage: 'Tell me more about SuperSupplier'
  }))));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
